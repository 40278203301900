import React, {useMemo, useRef} from "react";
import momentTZ from "moment-timezone";
import _ from "lodash";

import {useCurrentSelection} from "../../../hooks/useCurrentSelection";
import SensorGraphHeader from "../../../components/GraphsChartHeader/SensorGraphHeader";
// @ts-ignore
import ReactHighstock from "react-highcharts/ReactHighstock";

import {useIsTablet} from "../../../hooks/useIsTablet";
import {F500_DEVICE_TYPE} from "../index";

import "../index.css";
import classes from "../../../components/GraphsChart/SensorGraph.module.scss";

interface RenderGraphProps {
    sensorGraphDataInitial: any,
    groupName: string,
    sensorGraphDataArray: any,
    isLostComms: any,
    deviceType: string
    defaultOptions: any,
    sensorId: number
}

const RenderGraph: React.FC<RenderGraphProps> = ({
                                                     sensorGraphDataInitial,
                                                     groupName,
                                                     sensorGraphDataArray,
                                                     isLostComms,
                                                     defaultOptions,
                                                     sensorId,
                                                     deviceType,
                                                 }) => {
    const exportCSVSensorId = sensorGraphDataInitial[0]?.sensor
    const chartRef = useRef();
    const {site} = useCurrentSelection();

    const isTablet = useIsTablet();

    const legend = F500_DEVICE_TYPE.includes(deviceType) ? false : {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
    };


    const insertEmptySlots = (sensorGraphData: any, maxPeriodParam: number) => {
        return _.map(sensorGraphData, (graphObj: any) => {
            const isBooleanGraph = ["BOOL", "ENUM"].indexOf(graphObj.sensor_type) !== -1;

            const maxPeriod = maxPeriodParam * 60 * 1.15 * 1000;  // To timestamp delta (in ms)
            const newData: any = [];
            graphObj.data.forEach((dataArray: any, index: number) => {
                newData.push(dataArray);
                if (graphObj.data[index + 1] && graphObj.data[index + 1][0] - graphObj.data[index][0] > maxPeriod) {
                    _.times(Math.round(graphObj.data[index + 1][0] / dataArray[0] + 1), (emptyIndex: number) => {
                        const x = dataArray[0] + (maxPeriod - 4000) * (emptyIndex + 1);
                        let emptyDataEntry = [x, null, true];

                        // edit entry for Contact graphs
                        if (isBooleanGraph) {
                            emptyDataEntry = emptyDataEntry.slice(0, -1).concat([false, "Disabled"]);
                        }

                        newData.push(emptyDataEntry);
                    });
                }
            });

            return {
                ...graphObj,
                data: newData,
            };
        });
    };

    const sensorGraphData = insertEmptySlots(sensorGraphDataInitial, 30);

    const sensorWithValueLabel = sensorGraphData.find((graphObj: any) => graphObj.sensor_type === "NUMERIC");
    const sensorWithBooleanLabel = sensorGraphData.find((graphObj: any) => ["ENUM", "BOOL"].indexOf(graphObj.sensor_type) !== -1);

    const valueLabel = sensorWithValueLabel && sensorWithValueLabel.value_label;

    const series = useMemo(() => {
        return sensorGraphData.map((graphData: any) => {
            const getName = () => {
                const nameLength = graphData.name.length;
                if(nameLength >= 150){
                    const shortGraphName = graphData.name.split(' ').slice(0, 4).join(' ')
                    return `${shortGraphName} ...`
                }
                return graphData.name
            };
            const getId = () => {
                const ids = graphData?.sensor;
                const splittedIds = ids?.split(".");
                return ids?.split(".")[splittedIds?.length - 1];
            };

            const seriesParams: any = {};

            const isBooleanGraph = ["BOOL", "ENUM"].indexOf(graphData.sensor_type) !== -1;

            const seriesData = graphData.data.map((dataArray: any) => {
                const objToReturn = {
                    state: dataArray[3],
                    status: dataArray[2] ? "Alarm" : "OK",
                    x: dataArray[0],
                    y: dataArray[1],
                };

                const state = objToReturn.state;

                if (isBooleanGraph) {
                    objToReturn.y = objToReturn.status === "Alarm" ? 1 : -1;
                    seriesParams.type = "area";
                    seriesParams.yAxis = sensorWithValueLabel && 1;

                    if (objToReturn.y > 1 || objToReturn.y < -1) {
                        objToReturn.y = null;
                    }

                }

                if (isLostComms(state)) {
                    objToReturn.y = null;
                }

                return objToReturn;
            }).sort((a: any, b: any) => a.x - b.x);


            return {
                ...seriesParams,
                data: seriesData,
                name: getName(),
                id: getId(),
            };
        });
    }, [isLostComms, sensorGraphData, sensorWithValueLabel]);

    const yAxis = [];

    if (sensorWithValueLabel) {
        yAxis.push({
            allowDecimals: false,
            // height: sensorWithBooleanLabel && 200,
            lineWidth: 2,
            minTickInterval: 1,
            plotLines: [{
                color: "#808080",
                value: 0,
                width: 1,
            }],
            title: {
                text: `${groupName} (${valueLabel})`,
            },
        });
    }

    if (sensorWithBooleanLabel) {
        yAxis.push({
            allowDecimals: false,
            bottom: 50,
            contactAxis: true,
            gridLineWidth: 0,
            height: 90,
            labels: {
                enabled: false,
            },
            lineWidth: 2,
            max: 2,
            min: -2,
            offset: 0,
            plotBands: [
                { // OK
                    color: "rgba(68, 213, 170, 0.1)",
                    from: -1,
                    label: {
                        align: "right",
                        style: {
                            color: "#000",
                        },
                        text: "OK",
                        x: -10,
                    },
                    to: 0,
                },
                { // ALARM
                    color: "rgba(255, 150, 68, 0.2)",
                    from: 0,
                    label: {
                        align: "right",
                        style: {
                            color: "#606060",
                        },
                        text: "Alarm",
                        x: -10,
                    },
                    to: 1,
                },
            ],
            title: {
                text: "Contact",
            },
            top: sensorWithValueLabel && sensorWithBooleanLabel && 260,
        });
    }

    const getWidthGraph = () => {
        if (F500_DEVICE_TYPE.includes(deviceType)) {
            return isTablet ? window.innerWidth - 300 : window.innerWidth - 537;
        }
        return isTablet ? window.innerWidth - 170 : window.innerWidth - 300;
    };

    const responsive_width = getWidthGraph();
    const config: any = {
        ...defaultOptions,
        chart: {
            ...defaultOptions.chart,
            height: (sensorWithValueLabel && sensorWithBooleanLabel) ? 460 : sensorWithBooleanLabel && 210,
            width: responsive_width,
        },
        series: series.sort(function (a, b) {
            if(a?.name.toLowerCase() < b?.name.toLowerCase()) return -1;
            if(a?.name.toLowerCase() > b?.name.toLowerCase()) return 1;
            return 0;
        }),
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 1200,
                },
                chartOptions: {
                    legend: {
                        ...legend,
                        itemStyle: {
                            fontSize: "10px",
                        },
                        itemMarginTop: 5,
                    }
                }
            }],
        },
        title: {
            text: groupName,
        },
        tooltip: {
            useHTML: true,
            shared: false,
            borderRadius: 0,
            borderWidth: 0,
            formatter: function () {
                // @ts-ignore
                let res = momentTZ(this.x).tz(site?.timezone).format("D MMM YYYY, HH:mm:ss");
                res += ` ${site?.timezone}`;
                // @ts-ignore
                this.points.forEach((item: any) => {

                    const formatters = {
                        "Minutes": (value: any) => `${Math.floor(value / 60)}h:${value % 60}m`,
                    };

                    // eslint-disable-next-line no-useless-concat
                    res += "<span className=\"highcharts-container\" style=\"color:" + item.point.series.color + "\">" + " " + item.point.series.name + "</span>: <b>";

                    // get formatting function or return unchached
                    // @ts-ignore
                    const fmt = formatters[valueLabel] ? formatters[valueLabel] : (value: any, label: any) => `${value} ${label}`;

                    if (item.point.series.yAxis.userOptions.contactAxis) {
                        item.point.status = item.point.status || fmt(item.point.y, valueLabel);
                        res += item.point.status + "</b><br/>";
                    } else {
                        res += fmt(item.point.y, valueLabel) + "</b> " + item.point.status + "<br/>";
                    }
                });

                return res;
            },
        },
        xAxis: {
            labels: ({
                formatter(): any {
                    return momentTZ(this.value).tz(site?.timezone).format("HH:mm");
                },
            } as any),
            ordinal: false,
        },
        yAxis,
    };

    return (
        <React.Fragment>
            <div className={classes.graphsBlockWrap}>
                <SensorGraphHeader
                    device_type={`${sensorGraphDataArray.units} ${sensorGraphDataArray.value_label}`}
                    chartRef={chartRef}
                    sensorId={exportCSVSensorId}
                />

                <ReactHighstock ref={chartRef} config={config} key={groupName}/>

            </div>
        </React.Fragment>
    );
};

export default RenderGraph;
