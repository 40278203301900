import React from "react";
import {Button, Dropdown, Menu} from "antd";
import clsx from "clsx";

import HazardMon from "../../assets/img/HazardMon.svg";

import classes from "./LandingHeader.module.scss";
import {DownOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUserState} from "../../store/selectors";


interface HeaderProps {
    isRegister: boolean;
    onLogin: (isRegister: boolean) => void;
    activationLink?: boolean;
}


const LandingHeader: React.FC<HeaderProps> = ({isRegister, onLogin, activationLink}) => {
    const {auth_key} = useSelector(selectUserState);
    const history = useHistory();

    const state = useSelector(state => state)
    // @ts-ignore
    const largeLogoUrl: string = state?.authReducer?.layoutSettings?.logo
    const largeLogo = largeLogoUrl ? largeLogoUrl.replace(/\?(.*)/, '') : HazardMon

    const handleLogin = () => {
        if (activationLink) {
            return history.push("/");
        }
        onLogin(true);
    };


    const menu = (
        <Menu key={"feedback"}>
            <Menu.Item>
                <Link to="/feedback">
                    Send Feedback
                </Link>
            </Menu.Item>
        </Menu>
    );

    return (
        <div className={clsx("d-flex", classes.wrap)}>

            <div className={classes.logo}>
                <img src={largeLogo ? largeLogo : HazardMon}
                     className={clsx( largeLogo && classes.largeImg)}
                     alt="HazardMon"/>
            </div>

            <div className={classes.connect}>
                <div className={classes.connectWrap}>
                    <div className={classes.contactSelect}>
                    <Dropdown overlay={menu} placement="topRight" arrow>
                            <Button className={classes.select} type={"text"}>Contact Us <DownOutlined/></Button>
                        </Dropdown>
                    </div>
                </div>

                <div>
                    <div className={classes.linkWrap} onClick={handleLogin}>
                        {auth_key ? 'Close' : 'Login'}
                    </div>
                </div>

            </div>
        </div>
    );
};
export default LandingHeader;
